<template>
  <div class="home-articles">
    <div class="home-articles-cnt">
      <h2 class="heading">Tous les articles</h2>
      <CategoryTabs :active="tag || ''"/>
      <div class="articles">
        <ArticleListItem v-for="article of visibleArticles" :article="article" :key="article.slug" />
      </div>
      <CtaButton label="Plus d'articles" :black="true" :block="true" @click="onMore" v-show="isMoreAvailable" />
    </div>
  </div>
</template>

<script>
import ArticleListItem from './ArticleListItem.vue'
import CategoryTabs from './atoms/CategoryTabs.vue'
import CtaButton from './atoms/CtaButton.vue'

export default {
  name: "HomeArticles",
  components: {
    ArticleListItem,
    CategoryTabs,
    CtaButton,
  },

  props: {
    tag: {
      type: String,
      default: null,
    }
  },

  data() {
    return {
      visible: 9,
    }
  },

  computed: {
    articles() {
      return this.$root.articles;
    },
    filteredArticles() {
      if (!this.tag) {
        return this.articles;
      }
      return this.articles.filter(article => article.tag === this.tag);
    },
    visibleArticles() {
      return this.filteredArticles.slice(0, this.visible);
    },
    isMoreAvailable() {
      return this.visible < this.filteredArticles.length;
    },
  },

  methods: {
    onMore() {
      this.visible += 6;
    },
  },
};
</script>

<style lang="scss">
.home-articles {
  background-color: $neutral100;
  padding: 96px 0;

  .home-articles-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .heading {
      margin-bottom: 64px;
    }

    .category-tabs {
      margin-bottom: 64px;
    }

    .article-list-item {
      margin-right: 32px;
      float: left;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .articles {
      margin-bottom: 32px;
      display: flex;
      flex-wrap: wrap;
      
      &::after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
.home-articles {
  padding: 64px 0;

  .home-articles-cnt {
    width: $mobileContainer;

    .category-tabs {
      display: none;
    }
  }
}
}
</style>