<template>
  <div class="article-related" v-if="articles.length > 0">
    <div class="article-related-cnt">
      <div class="heading">Vous aimerez aussi</div>
      <div class="articles">
        <ArticleListItem v-if="articles.length > 0" :article="articles[0]" />
        <ArticleListItem v-if="articles.length > 1" :article="articles[1]" />
        <ArticleListItem v-if="articles.length > 2" :article="articles[2]" />
      </div>
    </div>
  </div>
</template>

<script>
import ArticleListItem from './ArticleListItem.vue';

export default {
  name: "ArticleRelated",
  components: {
    ArticleListItem,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },

  data() {
    const articles = this.$root.articles
      .filter(m => m.tag === this.article.tag)
      .filter(m => m.slug !== this.article.slug)

    return {
      articles,
    }
  }
};
</script>

<style lang="scss">
.article-related {
  background-color: $neutral100;
  padding: 96px 0;

  .article-related-cnt {
    width: $desktopContainer;
    margin: 0 auto;

    .heading {
      margin-bottom: 64px;
    }

    .category-tabs {
      margin-bottom: 64px;
    }

    .article-list-item {
      margin-right: 32px;
      float: left;

      &:last-child {
        margin-right: 0;
      }
    }

    .articles {
      padding-bottom: 64px;
      
      &::after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .article-related {
    padding: 64px 0 0;

    .article-related-cnt {
      width: $mobileContainer;
    } 
  }
}
</style>