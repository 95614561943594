<template>
  <div class="category-page">
    <CategoryHeader />
    <HighlightedArticles />
    <HomeArticles :tag="categorySlug" />
    <CategoryList />
  </div>
</template>

<script>
import CategoryHeader from '@/components/CategoryHeader.vue'
import HighlightedArticles from '@/components/HighlightedArticles.vue'
import HomeArticles from '@/components/HomeArticles.vue'
import CategoryList from '@/components/CategoryList.vue'

export default {
  metaInfo() {
    return {
      title: this.category.name + ' | Onlysales',
      htmlAttrs: {
        lang: 'fr-FR'
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {hid: 'description', name: 'description', content: this.category.description},
      ],
      link: [
        {rel: 'canonical', href: 'https://blog.onlysales.fr/' + this.category.slug}
      ]
    }
  },

  components: {
    CategoryHeader,
    HighlightedArticles,
    HomeArticles,
    CategoryList,
  },

  data() {
    return {}
  },
  computed: {
    categorySlug() {
      return this.$route.params.category;
    },
    category() {
      return this.$root.tags[this.categorySlug];
    },
  },
};
</script>

<style lang="scss">
.category-page {
  background-color: $neutral25;
}
</style>